<script>
  // core components
  // import CardSettings from "../components/Cards/CardSettings.svelte";
  // import CardProfile from "../components/Cards/CardProfile.svelte";
  export let location;
</script>

<div class="flex flex-wrap">
  <div class="w-full lg:w-8/12 px-4">
    
  </div>
  <div class="w-full lg:w-4/12 px-4">
    
  </div>
</div>
