<script>
    export let labelText = "";
    export let required = false;
    export let value = "";
    export let type = "text";
    export let fieldName = "";
    let inputID = Math.random().toString(36).slice(2, 12);

</script>
<label
    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
    for={inputID}
>
    {labelText}
    {#if required}
        <span class="text-red-500">*</span>
    {/if}
</label>
<textarea
    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
    name={fieldName}
    rows="6"
    id={inputID}
    {required}
>{value}</textarea>
