<script>
    import InlineButton from "../Buttons/InlineButton.svelte";
    import Modal from "../Modal/Modal.svelte";

    export let title = '';
    export let buttonText = 'Sil'
    export let buttonType = 'delete'
    export let onApprove = null;
    let visible = false;

    const onVisible = () => {
        visible = true;
    } 

    const onClose = () => {
        visible = false;
    }
</script>
<InlineButton text={buttonText} buttonType={buttonType} onClick={onVisible} /> 
<Modal
    visible={visible}
    title={title}
    onClose={onClose}
>
    <div slot="footer" class="flex w-full flex justify-center p-4">
        <InlineButton onClick={onClose} text='Vazgeç' buttonType='cancel' />
        <InlineButton 
            onClick={() => {
                onClose();
                onApprove();
            }} 
            text='Onayla' 
            buttonType='success'
        />
    </div>
    <slot />
</Modal>